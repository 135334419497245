<template>
  <!--Paging 传递值：-->
  <!--total是总条数，styles传入样式，也可以不传，currentPageChange是自定义事件名字-->
  <!--增加需求的话，比如keep-alive，可直接在这个组件上叠加功能-->
  <!--pageSize 必须传，每一页显示多少数据-->
  <el-pagination
    background
    :style="styles"
    @current-change="handleCurrentChange"
    :current-page="page"
    :page-size="pageSize"
    :layout="layout"
    :total="total">
  </el-pagination>
</template>
<script>
export default {
  name: 'PageComponents',
  props: {
    layout:{
      type:String,
      default:'total, prev, pager, next, jumper'
    },
    total: { // 总页数
      type: Number,
      default: 0
    },
    page: { // 当前页
      type: Number,
      default: 0
    },
    styles: { // style是一个关键字，不能用作变量名，so i had to add an 's'
      type: String,
      default: ''
    },
    pageSize: {
      type: Number,
      require: true
    }
  },
  methods: {
    // 页数改变后的回调
    handleCurrentChange (newPage) {
      this.$emit('currentPageChange', newPage)
    }
  }
}
</script>
<style lang="less">
  .el-pager li.active{
    color: #1abcfd;
    cursor: default;
  }
  .el-pager li:hover {
    color: #1abcfd;
  }
</style>
