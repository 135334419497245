<template>
  <div class="order-list">
    <h2>科技资源列表</h2>
    <div class="header-btn">
      <el-button type="primary" size="mini" @click="()=>{$router.go(-1)}">返回</el-button>
    </div>
    <div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column align="center" prop="user_name" label="预约人姓名"></el-table-column>
        <el-table-column align="center" prop="mobile" label="联系人电话"></el-table-column>
        <el-table-column align="center" prop="email" label="电子邮箱"></el-table-column>
        <el-table-column align="center" prop="company" label="公司名称"></el-table-column>
        <el-table-column align="center" prop="order_date" label="预约日期"></el-table-column>
      </el-table>
      <page class="page" :total="page.total" @currentPageChange="currentPageChange" />
    </div>
  </div>
</template>
<script>
import page from "@/components/PageComponents";
export default {
  name: "orderList",
  components: { page },
  data() {
    return {
      tableData: [], //表格数据
      organ_id: 0, //机构id
      page: { current_page: 0, per_page: 0, total: 0, last_page: 0 },
      nowPage: 1 //当前page
    };
  },
  created() {
    this.organ_id = sessionStorage.organizationId;
    this.orderList(this.$route.query.id, this.organ_id , 1);
  },
  methods: {
    //预约列表
    async orderList(id, organ_id, page) {
      const { data: res } = await this.$http.get(
        this.URL.adminUrl.instruments + id + "/orders",
        {
          params: { organ_id, page }
        }
      );
      this.tableData = res.data.data;
      this.page = res.data.meta;
      this.tableData.length > 0 &&
        this.tableData.map(item => {
          let arr = item.order_date.split(" ");
          item.order_date = arr[0];
          return item;
        });
      console.log(res, "预约列表");
    },
    //分页
    currentPageChange(e) {
      this.nowPage = e;
      this.orderList(this.$route.query.id, this.organ_id, e);
    }
  }
};
</script>
<style lang="less" scoped>
.order-list {
  .header-btn {
    margin: 20px 0;
  }
  .page {
    text-align: center;
    margin-top: 10px;
  }
}
</style>